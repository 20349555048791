import { connectNetwork } from '../lib/NetworkProvider'
import View from '../lib/View'
import moment from 'momentconfig'
import qs from 'qs'
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Form, Input, Button, Step, Icon, Header, Segment, Table, Dimmer, Loader, Progress } from 'semantic-ui-react'
import { baseViewDispatch, Default, initializePage, isNaNOrInfinity, Mobile, padToDigits } from '../lib/util'
import Labeling from './Labeling'
import { pdfjs, Document, Page } from 'react-pdf'
import { debounce } from 'throttle-debounce'
import axios from 'axios'
import WorkOrders from 'printing/WorkOrders'
import { SEARCH_ACTIONS } from '../actions/search'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

moment.locale('it')

class Packaging extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState()

        this.updateAssieme = () => {}
        // this.updateAssieme = debounce(1000, false, () => {
        //     axios
        //         .get(`${this.props.network.WEB_REQUEST_URL}get_ass_for_arti`, {
        //             params: {
        //                 arti: this.state.scannedArtiCode,
        //             },
        //         })
        //         .then((result) => {
        //             if (result && result.data.data[0] && result.data.data && result.data.data.length > 0) {
        //                 this.setState({
        //                     assieme: result.data.data[0],
        //                 })
        //             } else {
        //                 this.setState({
        //                     assieme: null,
        //                 })
        //             }
        //         })
        //         .catch((error) => {
        //             console.error('Error getting ass from arti', this.state.scannedArtiCode)
        //         })
        // })
    }

    loadArtiFromQString = async () => {
        return new Promise((resolve, reject) => {
            resolve()
        })
    }

    // loadArtiFromQString = async (query) => {
    //     return new Promise(async (resolve, reject) => {
    //         let artistr = []
    //         if (query.ordprods && query.ordprods.indexOf('|') >= 0) {
    //             artistr = query.ordprods.split('|')
    //         } else {
    //             artistr = [query.ordprods ? query.ordprods : '']
    //         }

    //         const artis = []
    //         for (const sartistr of artistr) {
    //             const split = sartistr.split(';')
    //             const op = split[0]
    //             const num = split[1]
    //             const description = split[2].replace(/%20/g, ' ')
    //             let ordprod = null
    //             let images = []
    //             try {
    //                 ordprod = await axios.get(`${this.props.network.WEB_REQUEST_URL}get_arti_from_codop`, {
    //                     params: {
    //                         codop: op,
    //                     },
    //                 })
    //             } catch (e) {
    //                 console.error('Error getting arti from codop', op)
    //             }

    //             const opobj = ordprod && ordprod.data && ordprod.data.data ? ordprod.data.data : {}
    //             const artiinfo = {
    //                 op,
    //                 num,
    //                 description,
    //                 name: description,
    //                 descrizio: description,
    //                 ordprod: opobj,
    //                 codice: opobj.codmod,
    //                 arti: opobj.codmod,
    //                 tipo: opobj.tipo,
    //                 ...opobj,
    //             }

    //             if (!artiinfo.workCode) {
    //                 artiinfo.workCode = opobj.codmod
    //             }

    //             if (!artiinfo.seq) {
    //                 artiinfo.seq = 1
    //             }

    //             try {
    //                 images = await axios.post(`${this.props.network.WEB_REQUEST_URL}find_image`, {
    //                     artiinfo,
    //                 })
    //             } catch (e) {
    //                 console.error('Error getting images from artiinfo', artiinfo)
    //             }

    //             if (images && images.data && images.data.data) {
    //                 artiinfo.images = images.data.data
    //             }
    //             // artiinfo.images = ['ACPL0453.bmp', 'ACPL0466.bmp', 'ACPL0482.bmp', 'ACPL0503.bmp'] For tests

    //             artis.push(artiinfo)
    //         }
    //         resolve(artis)
    //     })
    // }

    defaultState() {
        return {
            numPages: 0,
            pageNumber: 1,
            sessionDataForCode: null,
            scannedCode: '',
            scannedArtiCode: '',
            scannedCodop: '',
            verifiedCode: '',
            currentSessionData: null,
            startScanning: false,
            step: 0,
            wrongCode: false,
            errorMessage: '',
            fullPackageList: null,
            artisForCode: {},
            selectedWorkOrder: null,
            assieme: null,
            printing: false,
            hasQueryString: !!this.props.location.search && this.props.location.search !== '',
            artisFromCodop: [],
            selectedFromCodop: 0,
            loading: true,
            customeroverride: null,
            customeroverrideName: '',
            articleclientcodeoverride: null,
            codopquantity: 0,
            readyquantity: 0,
            numberOfPackagesToMake: 0,
            abort: false,
            selectedPackOrderObj: null,
            pause: false,
            dontPause: false,
            restartingBox: false,
        }
    }

    prev() {
        const { network } = this.props
        let { step } = this.state

        if (step === 2) {
            this.setState({ step: 0, scannedCode: '' })
        } else {
            this.setState({ step: Math.max(0, step - 1), scannedCode: '' })
        }

        network.getSessionData()
        network.getPackageList('', false, [], [], undefined, undefined, true)
        network.getStdArticleList('', [], [], true, undefined, undefined, true)
    }

    next() {
        let { step } = this.state

        this.setState({ step: Math.min(1, step + 1) })
    }

    getSessionKey(sessiondata) {
        let datestr = sessiondata.sessionDeliveryDate
            ? moment.utc(sessiondata.sessionDeliveryDate).format('YYYY_MM_DD')
            : moment().format('YYYY_MM_DD')
        if (!sessiondata.sessionClientOrder || sessiondata.sessionClientOrder === '00') {
            datestr = '1900_01_01'
            sessiondata.sessionClientOrder = '00'
        }
        //
        return `${sessiondata.sessionClientOrder ? sessiondata.sessionClientOrder.trim() : '00'}_${
            sessiondata.sessionArticleCode ? sessiondata.sessionArticleCode.trim() : ''
        }_${datestr}`
    }

    getSessionKeyFromArti(arti) {
        if (arti) {
            let datestr = arti.deliveryDate ? moment.utc(arti.deliveryDate).format('YYYY_MM_DD') : moment().format('YYYY_MM_DD')
            if (!arti.jobCode || arti.jobCode === '00') {
                datestr = '1900_01_01'
            }
            return `${arti.jobCode ? arti.jobCode.trim() : '00'}_${arti.workCode ? arti.workCode.trim() : ''}_${datestr}`
        } else return ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { network, fetching, session, fetchingArtis, artis } = this.props
        const {
            sessionDataForCode,
            verifiedCode: verifiedCodeFull,
            scannedArtiCode,
            currentSessionData,
            startScanning,
            selectedWorkOrder,
            pause,
        } = this.state

        if (this.state.pause && this.state.step === 0) {
            this.setState({ pause: false })
        }

        if (this.state.abort && !prevState.abort) {
            this.prev()
            this.setState({ step: 0, printing: false, pause: false, loading: false, abort: false })
        }

        if (this.state.step === 0) {
            setImmediate(() => {
                const scannedCodeEl = document.getElementById('form-input-control-article-code')
                if (scannedCodeEl) {
                    scannedCodeEl.focus()
                    scannedCodeEl.onblur = (event) => {
                        setTimeout(() => {
                            var blurEl = document.getElementById('form-input-control-article-code')
                            if (blurEl && this.state.step === 0) {
                                blurEl.focus()
                            } else {
                                if (blurEl) {
                                    blurEl.onblur = () => {}
                                }
                            }
                        }, 10)
                    }
                }
            })
        }

        const verifiedCode = verifiedCodeFull && verifiedCodeFull.length >= 8 ? verifiedCodeFull.substring(0, 8) : ''

        if (this.state.step === 1) {
            setImmediate(() => {
                const verifiedCodeEl = document.getElementById('form-input-control-article-verifiedcode')
                if (verifiedCodeEl) {
                    verifiedCodeEl.focus()
                }
            })
        }

        if (!fetching && prevProps.fetching) {
            const sessionDataForCode = {}
            if (session) {
                for (const sessiondata of session) {
                    let key = this.getSessionKey(sessiondata)

                    if (!sessionDataForCode[key]) {
                        sessionDataForCode[key] = []
                    }
                    sessionDataForCode[key].push(sessiondata)
                }
            }
            //
            this.setState({ sessionDataForCode })
        }

        if (
            this.props.network.getSyncJobs &&
            this.state.selectedWorkOrder &&
            this.state.selectedWorkOrder !== prevState.selectedWorkOrder &&
            !this.state.selectedWorkOrder.jobCode
        ) {
            this.props.network.getSyncJobs(this.state.selectedWorkOrder.workCode)
        }

        if (this.props.getSyncJobs && !this.props.getSyncJobs.fetching && prevProps.getSyncJobs.fetching) {
            const { selectedWorkOrder } = this.state
            const fromZanzar = this.props.getSyncJobs.data.data
            if (fromZanzar && fromZanzar.length > 0 && selectedWorkOrder) {
                selectedWorkOrder.clientWorkCode = fromZanzar[0]['Art Cli / For']
                let clientDescription = fromZanzar[0]['Descr Art Cli / For']
                let description = fromZanzar[0]['Descr']
                if (clientDescription && clientDescription.indexOf(' - ') >= 0) {
                    clientDescription = clientDescription.split(' - ')[1]
                }
                if (clientDescription && clientDescription !== ' ') {
                    selectedWorkOrder.description = clientDescription
                } else if (description && description !== ' ') {
                    selectedWorkOrder.description = description
                }
            }
            //
            this.setState({ selectedWorkOrder })
        }

        if (!fetchingArtis && prevProps.fetchingArtis) {
            const artisForCode = {}
            for (const key in artis) {
                const arti = artis[key]
                const code = arti.articleCode ? arti.articleCode.trim() : ''
                if (!artisForCode[code]) {
                    artisForCode[code] = []
                }
                artisForCode[code].push(arti)
            }
            //

            for (const key in artisForCode) {
                if (key && artisForCode[key]) {
                    artisForCode[key].sort((a, b) => {
                        const acrdate = a.deliveryDate
                        const bcrdate = b.deliveryDate

                        if (!acrdate) {
                            return 1
                        }

                        if (!bcrdate) {
                            return -1
                        }

                        const moma = moment(acrdate, 'YYYY-MM-DD')
                        const momb = moment(bcrdate, 'YYYY-MM-DD')

                        if (moma.isBefore(momb)) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                }
            }

            this.setState({ artisForCode })
        }

        if (this.state.scannedArtiCode.length > 0 && prevState.scannedArtiCode !== this.state.scannedArtiCode) {
            network.getPackageList(this.state.scannedArtiCode, false, [], [], undefined, undefined, true)

            this.updateAssieme()
        }

        if (prevProps.fetchingPackages && !this.props.fetchingPackages && !this.state.fullPackageList) {
            this.setState({
                fullPackageList: this.props.packages,
            })
        }

        // Article code verification -> If Verified, the counter for the current box is increased by 1
        if (verifiedCode.length >= 8) {
            if (currentSessionData.sessionKit === 'SINGOLO') {
                if (verifiedCode === scannedArtiCode && verifiedCode === currentSessionData.workCode) {
                    currentSessionData.sessionVerifiedPackages += 1
                    // currentSessionData.sessionTotalPackagesDone += 1
                    const totalSize = currentSessionData.targetPackage.pcsPerPackage
                        ? currentSessionData.targetPackage.pcsPerPackage
                        : currentSessionData.targetPackage.packageSize

                    if (currentSessionData.sessionVerifiedPackages * currentSessionData.sourcePackage.pcsPerPackage >= totalSize) {
                        // Box completed
                        currentSessionData.completed = true
                        this.setState({ verifiedCode: '', step: 2, wrongCode: false }, () => {
                            network.getSessionData()
                        })
                    } else {
                        // Filling box
                        this.setState({ verifiedCode: '', currentSessionData, wrongCode: false })
                    }
                    network.addSessionData(currentSessionData)
                } else {
                    // TODO WRONG CODE NOTIFY
                    this.setState({
                        verifiedCode: '',
                        wrongCode: true,
                        errorMessage: `E' stato rilevato il codice articolo ${scannedArtiCode} non conforme con la confezione in corso, rimuovere l'articolo se inserito nella confezione. Per procedere con l'imbustamento, prelevare articoli ${verifiedCode} oppure premere indietro per cambiare commessa`,
                    })
                }
            } else {
                let found = false
                for (const key in currentSessionData.kitArtis) {
                    const arti = currentSessionData.kitArtis[key]
                    if (verifiedCode === arti.workCode) {
                        arti.session.sessionVerifiedPackages += 1
                        // arti.session.sessionTotalPackagesDone += 1
                        network.addSessionData(arti.session)
                        found = true
                        break
                    }
                }

                if (!found) {
                    this.setState({ verifiedCode: '', wrongCode: true, errorMessage: `CODICE ERRATO PER IMBUSTAMENTO ${verifiedCode}` })
                } else {
                    let completed = true

                    for (const key in currentSessionData.kitArtis) {
                        const arti = currentSessionData.kitArtis[key]
                        if (
                            arti.session.sessionVerifiedPackages <
                            (arti.session.targetPackage.pcsPerPackage
                                ? arti.session.targetPackage.pcsPerPackage / arti.session.sourcePackage.pcsPerPackage
                                : arti.session.targetPackage.packageSize / arti.session.sourcePackage.packageSize)
                        ) {
                            completed = false
                            break
                        }
                    }

                    if (completed) {
                        // Box completed
                        for (const key in currentSessionData.kitArtis) {
                            const arti = currentSessionData.kitArtis[key]
                            arti.session.completed = true
                        }

                        this.setState({ verifiedCode: '', step: 2, wrongCode: false }, () => {
                            network.getSessionData()
                        })
                    } else {
                        // Filling box
                        this.setState({ verifiedCode: '', currentSessionData, wrongCode: false })
                    }
                }
            }
        }

        if (this.state.scannedCodop && this.state.scannedCodop !== prevState.scannedCodop) {
            try {
                // axios
                //     .get(`${this.props.network.WEB_REQUEST_URL}get_arti_from_codop`, {
                //         params: {
                //             codop: this.state.scannedCodop,
                //         },
                //     })
                //     .then(async (result) => {
                //         if (result && result.data && result.data.data) {
                //             const ordprodarti = result.data.data
                //             const articleclientcodeoverride = await this.props.network.getSyncJobs(ordprodarti.codmod)
                //             const fromZanzar = articleclientcodeoverride.data.data
                //             if (fromZanzar && fromZanzar.length > 0 && ordprodarti) {
                //                 ordprodarti.clientWorkCode = fromZanzar[0]['Art Cli / For']
                //             }
                //             this.setState({
                //                 customeroverride: ordprodarti && ordprodarti.customeroverride ? ordprodarti.customeroverride : null,
                //                 customeroverrideName: ordprodarti && ordprodarti.customerName ? ordprodarti.customerName : '',
                //                 articleclientcodeoverride: ordprodarti.clientWorkCode,
                //                 codopquantity: ordprodarti.qtot,
                //                 readyquantity: ordprodarti.giac,
                //             })
                //         } else {
                //             this.setState({
                //                 customeroverride: null,
                //                 customeroverrideName: '',
                //                 articleclientcodeoverride: null,
                //                 codopquantity: 0,
                //                 readyquantity: 0,
                //             })
                //         }
                //     })
            } catch (e) {
                console.error('Error getting arti from codop', this.state.scannedCodop)
                this.setState({
                    customeroverride: null,
                })
            }
        }
    }

    componentDidMount() {
        const { network } = this.props

        initializePage(this, [], [])

        network.getSessionData()
        network.getPackageList('', false, [], [], undefined, undefined, true)
        network.getStdArticleList('', [], [], true, undefined, undefined, true).then((result) => {
            this.setState({
                loading: false,
            })

            if (this.state.hasQueryString) {
                const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
                // this.loadArtiFromQString(query)
                //     .then((artis) => {
                //         //
                //         setImmediate(() => {
                //             this.setState(
                //                 {
                //                     artisFromCodop: artis,
                //                     selectedFromCodop: 0,
                //                     scannedCode: `${artis[0].workCode}${padToDigits(artis[0].codop, 6)}${padToDigits(artis[0].seq, 3)}`,
                //                     // selectedWorkOrder: artis[0],
                //                 },
                //                 () => {
                //                     this.handleInput(null)

                //                     setImmediate(() => {
                //                         let selectedWorkOrder

                //                         for (const element of this.state.artisForCode[this.state.scannedArtiCode]) {
                //                             if (element.jobcode && element.jobcode.trim() === artis[0].jobCode.trim()) {
                //                                 selectedWorkOrder = element
                //                             }
                //                         }

                //                         if (!selectedWorkOrder) {
                //                             selectedWorkOrder = this.state.artisForCode[this.state.scannedArtiCode][0]
                //                         }

                //                         this.setState({
                //                             selectedWorkOrder,
                //                         })
                //                     })
                //                 }
                //             )
                //         })
                //     })
                //     .catch((error) => {
                //         console.error('error')
                //     })
            }
        })
        network.getCustomerList(undefined, true)
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    async handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'scannedCode' && value && value.length > 0) {
            const scannedCodop = value
            this.props.searchResult(scannedCodop)
            this.props.network.getWorkOrder(scannedCodop, true).then((workOrder) => {
                this.props.network.getStdArticle(workOrder.data.articleId, undefined, true).then((article) => {
                    const scannedArtiCode = workOrder.data.articleId ? workOrder.data.workCode : ''
                    const scannedSeq = 0
                    /* eslint-disable */
                    // const scannedArtiCode = value.substring(0, 8)
                    // const scannedCodop = value.substring(8, 8 + 6)
                    // const scannedSeq = value.substring(8 + 6, value.length)
                    this.setState({
                        scannedArtiCode,
                        scannedCodop,
                        scannedSeq,
                        selectedWorkOrder: { ...workOrder.data, ...article },
                    })
                })
            })
        }

        this.setState({
            [name]: value,
        })
    }

    isSameSession(sessa, sessb) {
        if (sessa && !sessa.sessionClientOrder) sessa.sessionClientOrder = '00'
        if (sessb && !sessb.sessionClientOrder) sessb.sessionClientOrder = '00'
        const kita = sessa && sessa.sessionKit ? sessa.sessionKit : 'SINGOLO'
        const kitb = sessb && sessb.sessionKit ? sessb.sessionKit : 'SINGOLO'
        return (
            sessa !== null &&
            sessb !== null &&
            // sessa.targetPackage &&
            // sessb.targetPackage &&
            kita === kitb &&
            sessa.sessionArticleCode === sessb.sessionArticleCode &&
            sessa.sessionClientOrder === sessb.sessionClientOrder &&
            sessa.sessionPackagedIn === sessb.sessionPackagedIn
        )
    }

    doesSessionAlreadyExist(candidate) {
        if (!candidate) return false

        const { sessionDataForCode } = this.state
        const key = this.getSessionKey(candidate)
        console.log('Does session already exist', key, candidate, sessionDataForCode, sessionDataForCode[key])
        if (!!sessionDataForCode && !!sessionDataForCode[key]) {
            for (const data of sessionDataForCode[key]) {
                if (this.isSameSession(data, candidate) && data && data.sessionTargetPackage) {
                    console.log('Does session already exist', true)
                    return true
                }
            }
        }

        console.log('Does session already exist', false)
        return false
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    isPaused = () => {
        return this.state.pause
    }

    resetCounter = () => {
        return axios
            .get(`${this.props.network.WEB_REQUEST_URL}set_packager_counter`, {
                params: {
                    count: 0,
                },
            })
            .then((result) => {
                // axios
                //     .get(`${this.props.network.WEB_REQUEST_URL}get_packager_info`, {
                //         params: {},
                //     })
                //     .then((result) => {
                //         const { currentCounter } = result.data
                //         const counter = currentCounter
                //         this.setState({
                //             packagerCounter: counter,
                //         })
                //         return counter
                //     })
                //     .catch((error) => {
                //         console.error('Error getting Packager info', error)
                //         return 0
                //     })
            })
            .catch((error) => {
                console.error('Error setting Packager counter')
            })
    }

    render() {
        const {
            numPages,
            pageNumber,
            artisForCode,
            sessionDataForCode,
            verifiedCode,
            step,
            scannedCode,
            scannedArtiCode,
            scannedCodop,
            currentSessionData,
            startScanning,
            newSessionData,
            fullPackageList,
            selectedWorkOrder,
        } = this.state
        const { network, packages } = this.props

        console.log('artisForCode', scannedArtiCode, artisForCode, artisForCode && artisForCode[scannedArtiCode])

        //

        //

        const ordering = {
            PZ: 0,
            BU: 1,
            SC: 2,
            BA: 3,
        }
        const packorder = []
        console.log('rendering with ', selectedWorkOrder, scannedArtiCode)
        if (selectedWorkOrder) {
            const arti = selectedWorkOrder
            const packsDone = {
                PZ: false,
                BU: false,
                SC: false,
                BA: false,
            }
            console.log('rendering with arti.packages ', arti.packages)
            for (const packageIndex in arti.packages) {
                const orderingTarget = arti.packages[packageIndex].packageTypeCode.substring(0, 2)
                //

                const pack = arti.packages[packageIndex]

                if (!arti.packages[packageIndex].packageParent) {
                    let foundIndex = null

                    const packageType = arti.packages[packageIndex].packageTypeCode.substring(0, 2)
                    let toFind = ''
                    switch (packageType) {
                        default:
                        case 'PZ':
                            toFind = 'BU'
                            break
                        case 'BU':
                            toFind = 'SC'
                            break
                        case 'SC':
                            toFind = 'BA'
                            break
                        case 'BA':
                            continue
                    }
                    //

                    for (const parentIndex in arti.packages) {
                        const toCheck = arti.packages[parentIndex].packageTypeCode.substring(0, 2)
                        //
                        if (toCheck === toFind) {
                            foundIndex = arti.packages[parentIndex].packageId
                            break
                        }
                    }

                    if (!foundIndex && toFind === 'BU') {
                        toFind = 'SC'

                        for (const parentIndex in arti.packages) {
                            const toCheck = arti.packages[parentIndex].packageTypeCode.substring(0, 2)
                            //
                            if (toCheck === toFind) {
                                foundIndex = arti.packages[parentIndex].packageId
                                break
                            }
                        }
                    }

                    pack.packageParent = foundIndex
                }

                let basePackage = null
                for (const pack of arti.packages) {
                    if (pack.packageTypeCode.substring(0, 2) === 'PZ') {
                        basePackage = pack
                        break
                    }
                }

                const sourcePackage = arti.packages[packageIndex]
                const targetPackage = arti.packageobj[pack.packageParent]

                let probe = basePackage

                while (probe) {
                    if (!probe.packageParent) {
                        let foundIndex = null

                        const packageType = probe.packageTypeCode.substring(0, 2)
                        let toFind = null
                        switch (packageType) {
                            case 'PZ':
                                toFind = 'BU'
                                break
                            case 'BU':
                                toFind = 'SC'
                                break
                            case 'SC':
                                toFind = 'BA'
                                break
                            default:
                            case 'BA':
                                break
                        }

                        if (toFind !== null) {
                            for (const parentIndex in selectedWorkOrder.packages) {
                                const toCheck = selectedWorkOrder.packages[parentIndex].packageTypeCode.substring(0, 2)
                                if (toCheck === toFind) {
                                    foundIndex = selectedWorkOrder.packages[parentIndex].packageId
                                    break
                                }
                            }

                            probe.packageParent = foundIndex
                        }
                    }
                    probe = arti.packageobj[probe.packageParent]
                }

                console.log('totalPcsInPackage', sourcePackage.packageTypeCode, 'targetPackage', targetPackage && targetPackage.packageTypeCode)
                if (targetPackage && !targetPackage.packageParent) {
                    let foundIndex = null

                    const packageType = targetPackage.packageTypeCode.substring(0, 2)
                    let toFind = null
                    switch (packageType) {
                        case 'PZ':
                            toFind = 'BU'
                            break
                        case 'BU':
                            toFind = 'SC'
                            break
                        case 'SC':
                            toFind = 'BA'
                            break
                        default:
                        case 'BA':
                            break
                    }

                    if (toFind !== null) {
                        for (const parentIndex in selectedWorkOrder.packages) {
                            const toCheck = selectedWorkOrder.packages[parentIndex].packageTypeCode.substring(0, 2)
                            if (toCheck === toFind) {
                                foundIndex = selectedWorkOrder.packages[parentIndex].packageId
                                break
                            }
                        }

                        targetPackage.packageParent = foundIndex
                    }
                }

                let totalPcsInPackage = targetPackage.pcsPerPackage

                let isBancale = false
                let targetPackageType = null
                if (targetPackage) {
                    sourcePackage.packageParent = targetPackage.packageId
                    targetPackageType = targetPackage.packageTypeCode.substring(0, 2)
                    if (targetPackageType === 'BA') {
                        isBancale = true
                    }
                }

                let sourceSize = sourcePackage.pcsPerPackage ? sourcePackage.pcsPerPackage : sourcePackage.packageSize
                let targetSize = arti.packageobj[pack.packageParent] ? arti.packageobj[pack.packageParent].packageSize : 0

                if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                    targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                }

                const candidate = {
                    sessionClientOrder: arti.jobCode,
                    sessionArticleCode: arti.workCode,
                    sessionPackagedIn: pack.packageId,
                    sessionTargetPackage: pack.packageParent,
                    sessionTargetPackageCount: targetSize,
                    sessionDeliveryDate: arti.deliveryDate,
                    sessionKit: arti.jobCode ? arti.kit : arti.kit_code,
                    addToSourceCounter: false,
                }

                console.log('Checking if session already exists', candidate, this.doesSessionAlreadyExist(candidate))
                if (this.doesSessionAlreadyExist(candidate)) {
                    continue
                }

                if (!targetPackage && sourcePackage.packageTypeCode !== 'PZ') {
                    continue
                } else {
                    //
                }

                //

                let numberOfPackagesToMake = 0
                if (selectedWorkOrder && selectedWorkOrder.productQuantity) {
                    numberOfPackagesToMake = Math.ceil(selectedWorkOrder.productQuantity / totalPcsInPackage)
                }

                if (!numberOfPackagesToMake) {
                    numberOfPackagesToMake = 1000
                }

                const isSingle =
                    (arti.jobCode && arti.kit === 'SINGOLO') || (!arti.jobCode && arti.kit_code && arti.kit_code === 'SINGOLO') || !arti.kitArtis

                const packorderobj = {
                    targetPackage,
                    packageOrder: ordering[orderingTarget] >= 0 ? ordering[orderingTarget] : 4,
                    orderingTarget,
                }

                const printFunc = async (updateState) => {
                    let targetSize = arti.packageobj[pack.packageParent] ? arti.packageobj[pack.packageParent].packageSize : 0

                    if (isSingle) {
                        if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                            targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                        }

                        const sessionObj = {
                            sessionClientOrder: arti.jobCode,
                            sessionArticleCode: arti.workCode,
                            sessionPackagedIn: pack.packageId,
                            sessionTargetPackage: pack.packageParent,
                            sessionTargetPackageCount: targetSize,
                            sessionKit: arti.jobCode ? arti.kit : arti.kit_code,
                            sessionDeliveryDate: arti.deliveryDate,
                            addToSourceCounter: false,
                        }

                        await network.addSessionData(sessionObj)

                        if (updateState) {
                            this.setState({
                                startScanning: true,
                                currentSessionData: {
                                    ...arti,
                                    ...sessionObj,
                                    sourcePackage,
                                    targetPackage,
                                    sessionAvailablePackages: 0,
                                    sessionVerifiedPackages: 0,
                                },
                                step: 2, // Change to re-enable verification
                                numberOfPackagesToMake,
                                selectedPackOrderObj: packorderobj,
                            })
                        }

                        return {
                            startScanning: true,
                            currentSessionData: {
                                ...arti,
                                ...sessionObj,
                                sourcePackage,
                                targetPackage,
                                sessionAvailablePackages: 0,
                                sessionVerifiedPackages: 0,
                            },
                            step: 2, // Change to re-enable verification
                            numberOfPackagesToMake,
                            selectedPackOrderObj: packorderobj,
                        }
                    } else {
                        let sobj = {}

                        for (const key in arti.kitArtis) {
                            const kitArti = arti.kitArtis[key]
                            if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                                targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                            }

                            const sessionObj = {
                                sessionClientOrder: kitArti.jobCode,
                                sessionArticleCode: kitArti.workCode,
                                sessionPackagedIn: pack.packageId,
                                sessionTargetPackage: pack.packageParent,
                                sessionTargetPackageCount: targetSize,
                                sessionKit: arti.jobCode ? kitArti.kit : kitArti.kit_code,
                                sessionDeliveryDate: kitArti.deliveryDate,
                                addToSourceCounter: false,
                            }

                            await network.addSessionData(sessionObj)

                            if (scannedArtiCode === kitArti.workCode) {
                                sobj = {
                                    ...kitArti,
                                    ...sessionObj,
                                    sourcePackage,
                                    targetPackage,
                                    sessionAvailablePackages: 0,
                                    sessionVerifiedPackages: 0,
                                    sessionDeliveryDate: arti.deliveryDate,
                                    kitArtis: arti.kitArtis,
                                }
                            }

                            kitArti.session = {
                                ...kitArti,
                                ...sessionObj,
                                sourcePackage,
                                targetPackage,
                                sessionAvailablePackages: 0,
                                sessionDeliveryDate: kitArti.deliveryDate,
                                sessionVerifiedPackages: 0,
                            }
                        }

                        if (updateState) {
                            this.setState(
                                {
                                    currentSessionData: sobj,
                                    startScanning: true,
                                    step: 1,
                                    numberOfPackagesToMake,
                                    selectedPackOrderObj: packorderobj,
                                },
                                () => {
                                    const verifiedCodeEl = document.getElementById('form-input-control-article-verifiedcode')
                                    if (verifiedCodeEl) {
                                        verifiedCodeEl.onblur = (event) => {
                                            setTimeout(() => {
                                                var blurEl = document.getElementById('form-input-control-article-verifiedcode')

                                                if (blurEl && this.state.step === 1) {
                                                    blurEl.focus()
                                                } else {
                                                    if (blurEl) {
                                                        blurEl.onblur = () => {}
                                                    }
                                                }
                                            }, 10)
                                        }
                                    }
                                }
                            )
                        }

                        return {
                            currentSessionData: sobj,
                            startScanning: true,
                            step: 1,
                            numberOfPackagesToMake,
                            selectedPackOrderObj: packorderobj,
                        }
                    }
                }

                const button = (
                    <Table.Row>
                        <Table.Cell>{targetPackage ? targetPackage.packageName : sourcePackage.packageName}</Table.Cell>
                        <Table.Cell>{isNaNOrInfinity(targetSize / sourceSize) ? 'N/A' : targetSize / sourceSize}</Table.Cell>

                        {!isSingle && sourcePackage.packageTypeCode !== 'PZ' && !isBancale && (
                            <Table.Cell>
                                {arti.kitArtis.map((value, index) => {
                                    return (
                                        <Header key={`${value.workCode}_${index}`} as="h4">
                                            {value.articleCode}
                                        </Header>
                                    )
                                })}
                            </Table.Cell>
                        )}

                        {(isSingle || isBancale || sourcePackage.packageTypeCode === 'PZ') && (
                            <Table.Cell>
                                <b>{arti.articleCode}</b>
                            </Table.Cell>
                        )}

                        <Table.Cell>0</Table.Cell>

                        <Table.Cell>
                            <Header as="h4">{isNaNOrInfinity(numberOfPackagesToMake) ? 'N/A' : numberOfPackagesToMake}</Header>
                        </Table.Cell>

                        <Table.Cell>
                            {sourcePackage.packageTypeCode !== 'PZ' && !isBancale && (
                                <Button
                                    color="grey"
                                    onClick={() => {
                                        printFunc(true)
                                    }}
                                >
                                    Stampa
                                </Button>
                            )}

                            {(sourcePackage.packageTypeCode === 'PZ' || isBancale) && (
                                <Button
                                    color="yellow"
                                    onClick={async () => {
                                        if (isSingle && !arti.kitArtis) {
                                            if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                                                targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                                            }

                                            const sessionObj = {
                                                sessionClientOrder: arti.jobCode,
                                                sessionArticleCode: arti.workCode,
                                                sessionPackagedIn: pack.packageId,
                                                sessionTargetPackage: pack.packageParent,
                                                sessionTargetPackageCount: targetSize,
                                                sessionDeliveryDate: arti.deliveryDate,
                                                sessionKit: arti.jobCode ? arti.kit : arti.kit_code,
                                                addToSourceCounter: false,
                                            }

                                            await network.addSessionData(sessionObj)

                                            this.setState({
                                                startScanning: true,
                                                currentSessionData: {
                                                    ...arti,
                                                    ...sessionObj,
                                                    sourcePackage,
                                                    targetPackage,
                                                    sessionAvailablePackages: 0,
                                                    sessionVerifiedPackages: 0,
                                                },
                                                step: 2,
                                                numberOfPackagesToMake,
                                            })
                                        } else {
                                            let sobj = {}

                                            for (const key in arti.kitArtis) {
                                                const kitArti = arti.kitArtis[key]
                                                if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                                                    targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                                                }

                                                const sessionObj = {
                                                    sessionClientOrder: kitArti.jobCode,
                                                    sessionArticleCode: kitArti.workCode,
                                                    sessionPackagedIn: pack.packageId,
                                                    sessionTargetPackage: pack.packageParent,
                                                    sessionTargetPackageCount: targetSize,
                                                    sessionDeliveryDate: kitArti.deliveryDate,
                                                    sessionKit: arti.jobCode ? kitArti.kit : kitArti.kit_code,
                                                    addToSourceCounter: false,
                                                }

                                                await network.addSessionData(sessionObj)

                                                if (scannedArtiCode === kitArti.workCode) {
                                                    sobj = {
                                                        ...kitArti,
                                                        ...sessionObj,
                                                        sourcePackage,
                                                        targetPackage,
                                                        sessionDeliveryDate: arti.deliveryDate,
                                                        sessionAvailablePackages: 0,
                                                        sessionVerifiedPackages: 0,
                                                        kitArtis: arti.kitArtis,
                                                    }
                                                }

                                                kitArti.session = {
                                                    ...kitArti,
                                                    ...sessionObj,
                                                    sourcePackage,
                                                    targetPackage,
                                                    sessionDeliveryDate: kitArti.deliveryDate,
                                                    sessionAvailablePackages: 0,
                                                    sessionVerifiedPackages: 0,
                                                }
                                            }

                                            sobj.kitArtis = arti.kitArtis
                                            sobj.sourcePackage = sourcePackage
                                            sobj.targetPackage = arti.packageobj[pack.packageParent]
                                            sobj.sessionTargetPackageCount = targetSize

                                            this.setState({
                                                currentSessionData: sobj,
                                                startScanning: true,
                                                step: 2,
                                                numberOfPackagesToMake,
                                            })
                                        }
                                    }}
                                >
                                    Stampa
                                </Button>
                            )}
                        </Table.Cell>
                    </Table.Row>
                )

                //
                // if (!packsDone[orderingTarget]) {
                // packsDone[orderingTarget] = true
                packorderobj.button = button
                packorderobj.printFunc = printFunc
                packorder.push(packorderobj)
                // }
            }
            //
        }

        let key = ''

        if (selectedWorkOrder) {
            key = this.getSessionKeyFromArti(selectedWorkOrder)
        }

        console.log(
            'Session data for code is',
            sessionDataForCode,
            selectedWorkOrder,
            key,
            selectedWorkOrder && sessionDataForCode && sessionDataForCode[key]
        )
        if (selectedWorkOrder && sessionDataForCode && sessionDataForCode[key]) {
            for (const index in sessionDataForCode[key]) {
                const sobj = sessionDataForCode[key][index]
                const { sourcePackage, targetPackage } = sobj

                //

                if (
                    !targetPackage ||
                    (sobj.sessionClientOrder !== '00' && sobj.sessionClientOrder.trim() !== selectedWorkOrder.jobCode.trim()) ||
                    (selectedWorkOrder.workCode && selectedWorkOrder.workCode.trim() !== sobj.sessionArticleCode)
                ) {
                    continue
                }

                let targetPackageType = null
                let isBancale = false
                if (targetPackage) {
                    sourcePackage.packageParent = targetPackage.packageId
                    targetPackageType = targetPackage.packageTypeCode.substring(0, 2)
                    if (targetPackageType === 'BA') {
                        isBancale = true
                    }
                }

                const orderingTarget = sourcePackage.packageTypeCode.substring(0, 2)

                if (targetPackage && !targetPackage.packageParent) {
                    let foundIndex = null

                    const packageType = targetPackage.packageTypeCode.substring(0, 2)
                    let toFind = null
                    switch (packageType) {
                        case 'PZ':
                            toFind = 'BU'
                            break
                        case 'BU':
                            toFind = 'SC'
                            break
                        case 'SC':
                            toFind = 'BA'
                            break
                        default:
                        case 'BA':
                            break
                    }

                    if (toFind !== null) {
                        //

                        for (const parentIndex in selectedWorkOrder.packages) {
                            const toCheck = selectedWorkOrder.packages[parentIndex].packageTypeCode.substring(0, 2)
                            //
                            if (toCheck === toFind) {
                                foundIndex = selectedWorkOrder.packages[parentIndex].packageId
                                break
                            }
                        }

                        targetPackage.packageParent = foundIndex
                    }
                }

                let color = 'blue'
                if (this.isSameSession(sobj, currentSessionData)) {
                    color = 'green'
                }

                let completed = false
                if (sobj.sessionVerifiedPackages === sobj.sessionTargetPackageCount || sourcePackage.packageTypeCode === 'PZ') {
                    //
                    completed = true
                    color = 'yellow'
                }

                if (sobj.sessionKit !== 'SINGOLO') {
                    completed = true
                    for (const arti of sobj.kitArtis) {
                        if (arti.session.sessionVerifiedPackages < arti.session.sessionTargetPackageCount) {
                            completed = false
                            break
                        }
                    }

                    if (completed || sourcePackage.packageTypeCode === 'PZ') {
                        color = 'yellow'
                    }
                }

                let numberOfPackagesToMake = 0
                if (selectedWorkOrder && selectedWorkOrder.productQuantity) {
                    numberOfPackagesToMake = Math.ceil(selectedWorkOrder.productQuantity / sobj.sessionTargetPackageCount)
                }

                if (!numberOfPackagesToMake) {
                    numberOfPackagesToMake = 1000
                }

                const packorderobj = {
                    targetPackage,
                    packageOrder: ordering[orderingTarget] >= 0 ? ordering[orderingTarget] : 4,
                    orderingTarget,
                }

                const printFunc = (updateState) => {
                    if (completed || sourcePackage.packageTypeCode === 'PZ' || isBancale) {
                        if (updateState) {
                            this.setState({
                                startScanning: true,
                                currentSessionData: sobj,
                                step: 2,
                                numberOfPackagesToMake,
                                selectedPackOrderObj: packorderobj,
                            })
                        }
                        return {
                            startScanning: true,
                            currentSessionData: sobj,
                            step: 2,
                            numberOfPackagesToMake,
                            selectedPackOrderObj: packorderobj,
                        }
                    } else {
                        // if (sobj.sessionKit === 'SINGOLO') {
                        //     this.setState({
                        //         startScanning: true,
                        //         currentSessionData: sobj,
                        //         step: 2,
                        //     })
                        // } else { ARTI0003220001002 ARTI0738221002001
                        let skipVerify = sobj.sessionVerifiedPackages >= sobj.sessionTargetPackageCount / sobj.sourcePackage.pcsPerPackage
                        if (sobj.sessionKit !== 'SINGOLO' && sobj.kitArtis) {
                            for (const key in sobj.kitArtis) {
                                const arti = sobj.kitArtis[key]
                                if (arti.session.sessionVerifiedPackages < sobj.sessionTargetPackageCount / sobj.sourcePackage.pcsPerPackage) {
                                    skipVerify = false
                                }

                                // if (
                                //     arti.session.sessionVerifiedPackages <
                                //     arti.session.sessionTargetPackageCount / arti.session.sourcePackage.pcsPerPackage
                                // ) {
                                //     skipVerify = false
                                // }
                            }
                        }

                        if (skipVerify) {
                            if (updateState) {
                                this.setState({
                                    startScanning: true,
                                    currentSessionData: sobj,
                                    step: 2,
                                    numberOfPackagesToMake,
                                    selectedPackOrderObj: packorderobj,
                                })
                            }
                            return {
                                startScanning: true,
                                currentSessionData: sobj,
                                step: 2,
                                numberOfPackagesToMake,
                                selectedPackOrderObj: packorderobj,
                            }
                        } else {
                            if (updateState) {
                                this.setState(
                                    {
                                        startScanning: true,
                                        currentSessionData: sobj,
                                        step: 1,
                                        selectedPackOrderObj: packorderobj,
                                    },
                                    () => {
                                        const verifiedCodeEl = document.getElementById('form-input-control-article-verifiedcode')
                                        if (verifiedCodeEl) {
                                            verifiedCodeEl.onblur = (event) => {
                                                setTimeout(() => {
                                                    var blurEl = document.getElementById('form-input-control-article-verifiedcode')

                                                    if (blurEl && this.state.step === 1) {
                                                        blurEl.focus()
                                                    } else {
                                                        if (blurEl) {
                                                            blurEl.onblur = () => {}
                                                        }
                                                    }
                                                }, 10)
                                            }
                                        }
                                    }
                                )
                            }
                            return {
                                startScanning: true,
                                currentSessionData: sobj,
                                step: 1,
                                selectedPackOrderObj: packorderobj,
                            }
                        }
                        // }
                    }
                }

                console.log('Pushing a button for SESSION package', { targetPackage, sourcePackage, sobj })
                const button = (
                    <Table.Row>
                        <Table.Cell>{targetPackage ? targetPackage.packageName : sourcePackage.packageName}</Table.Cell>
                        <Table.Cell>
                            {isNaNOrInfinity(sobj.sessionTargetPackageCount / sobj.sourcePackage.pcsPerPackage)
                                ? 'N/A'
                                : sobj.sessionTargetPackageCount / sobj.sourcePackage.pcsPerPackage}
                        </Table.Cell>
                        <Table.Cell>
                            {sobj.sessionKit !== 'SINGOLO' ? (
                                <>
                                    <Header as="h4" style={{ color: 'white' }}>
                                        Kit formato da:
                                    </Header>
                                    {sobj.kitArtis.map((value, index) => {
                                        return (
                                            <Header as="h4" style={{ color: 'white' }}>
                                                {value.workCode}
                                            </Header>
                                        )
                                    })}
                                </>
                            ) : (
                                <b>{sobj.sessionArticleCode}</b>
                            )}
                        </Table.Cell>

                        <Table.Cell>
                            <Header as="h4">{sobj.sessionTotalPackagesDone}</Header>
                        </Table.Cell>

                        <Table.Cell>
                            <Header as="h4">{isNaNOrInfinity(numberOfPackagesToMake) ? 'N/A' : numberOfPackagesToMake}</Header>
                        </Table.Cell>

                        <Table.Cell>
                            <Button
                                color={'green'}
                                onClick={() => {
                                    printFunc(true)
                                }}
                            >
                                {completed || sourcePackage.packageTypeCode === 'PZ' || isBancale ? 'Stampa' : 'Completa'}
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                )
                // ARTI1025

                //
                packorderobj.button = button
                packorderobj.printFunc = printFunc
                packorder.push(packorderobj)
            }
        }

        //
        const buttons = []
        packorder.sort((a, b) => {
            return a.packageOrder <= b.packageOrder ? -1 : 1
        })

        console.log('Pack order is', packorder)

        for (let packorderindex = 0; packorderindex < packorder.length; packorderindex++) {
            const value = packorder[packorderindex]
            // TODO search next value based on packtype
            const nextvalue = packorder[packorderindex + 1]
            if (nextvalue) {
                value.nextPack = nextvalue
            }

            buttons.push(value.button)
        }

        //
        //

        let toFill = 0
        if (selectedWorkOrder && currentSessionData && currentSessionData.targetPackage && selectedWorkOrder.packageobj) {
            const packToFill = selectedWorkOrder.packageobj[currentSessionData.targetPackage.packageParent]
            //
            if (packToFill) {
                toFill = packToFill.pcsPerPackage / currentSessionData.targetPackage.pcsPerPackage
            }
        }

        let innerLabeling = null
        let progress = 0

        try {
            if (currentSessionData) {
                let totalDone = currentSessionData.sessionTotalPackagesDone ? currentSessionData.sessionTotalPackagesDone : 0
                //
                progress = ((totalDone % toFill) / toFill) * 100
                //
                if (progress === 0) progress = 100
            }

            if (
                this.state.step === 2 &&
                !this.state.pause &&
                this.state.selectedPackOrderObj &&
                this.state.printing &&
                !this.state.loading &&
                (this.props.printer === 'zebraimball' || this.props.printer === 'zebraimball2') &&
                currentSessionData &&
                currentSessionData.sessionTotalPackagesDone % toFill === 0 &&
                !this.state.dontPause &&
                (!currentSessionData.sessionKit || currentSessionData.sessionKit === 'SINGOLO')
            ) {
                setTimeout(() => {
                    this.setState({ pause: true })
                }, 0)
            }

            if (
                this.state.pause &&
                this.state.selectedPackOrderObj &&
                this.state.printing &&
                !this.state.loading &&
                (this.props.printer === 'zebraimball' || this.props.printer === 'zebraimball2') &&
                currentSessionData &&
                currentSessionData.sessionTotalPackagesDone % toFill === 0
            ) {
                const nextPackOrder = this.state.selectedPackOrderObj.nextPack
                const { packageOrder, orderingTarget, printFunc, button } = nextPackOrder
                const printData = printFunc(false)
                const { currentSessionData } = printData
                //

                // if (currentSessionData && currentSessionData.sessionKit === 'SINGOLO') {
                innerLabeling = (
                    <Labeling
                        useOwnPrinter={true}
                        abort={false}
                        numberOfPackagesToMake={1}
                        articleId={scannedArtiCode}
                        packageId={
                            currentSessionData && currentSessionData.targetPackage
                                ? currentSessionData.targetPackage.packageId
                                : currentSessionData.sourcePackage
                        }
                        customerId={currentSessionData.customerId ? currentSessionData.customerId : this.state.customeroverride}
                        customerName={this.state.customeroverride ? this.state.customeroverrideName : null}
                        jobCode={currentSessionData.sessionClientOrder}
                        arti={{ ...selectedWorkOrder, op: this.state.scannedCodop, lotto: this.state.scannedCodop }}
                        currentSession={currentSessionData}
                        onStartPrinting={() => {}}
                        onPrintLabel={(remaining) => {
                            return network
                                .addSessionData({
                                    ...currentSessionData,
                                    addToSourceCounter: true,
                                    printed: true,
                                })
                                .then((result) => {
                                    this.setState({ abort: false, pause: false, dontPause: true })
                                    //this.prev()
                                })
                        }}
                        isEmployee={true}
                    />
                )
                // }
            }
        } catch (e) {
            console.error('Error is', e)
        }

        //

        return (
            <Dimmer.Dimmable as={View} column fullw fullh dimmed={this.state.printing || this.state.loading}>
                <Dimmer active={this.state.printing} inverted className="full-screen-dimmer">
                    {!this.state.loading && this.props.printer !== 'zebraimball' && this.props.printer !== 'zebraimball2' && (
                        <Loader>
                            Stampa in corso, non chiudere la pagina...
                            <Button
                                onClick={() => {
                                    this.setState({ abort: true })
                                }}
                            >
                                Interrompi
                            </Button>
                        </Loader>
                    )}
                    {!this.state.loading && (this.props.printer === 'zebraimball' || this.props.printer === 'zebraimball2') && (
                        <View fullw fullh>
                            <Segment style={{ width: '100%', height: '100%' }}>
                                {!innerLabeling && (
                                    <Segment placeholder>
                                        <Header icon>
                                            <Icon name="hourglass outline" />
                                            Imbustatrice pronta all{"'"}utilizzo. In attesa di buste...{' '}
                                            {currentSessionData ? currentSessionData.sessionTotalPackagesDone % toFill : 0} di {toFill}
                                        </Header>
                                    </Segment>
                                )}
                                {innerLabeling}
                                <View style={{ position: 'absolute', bottom: 16, left: 16, right: 16 }}>
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.setState({ abort: true })
                                        }}
                                    >
                                        Interrompi
                                    </Button>
                                    <Button
                                        color="grey"
                                        onClick={() => {
                                            // this.setState({ pause: false, dontPause: true, restartingBox: true })

                                            // this.resetCounter().then(() => {
                                            const newAmount =
                                                currentSessionData.sessionTotalPackagesDone - (currentSessionData.sessionTotalPackagesDone % toFill)

                                            // axios
                                            //     .post(`${this.props.network.WEB_REQUEST_URL}set_session_data`, {
                                            //         sessionobj: { ...currentSessionData, sessionTotalPackagesDone: newAmount },
                                            //     })
                                            //     .then((result) => {
                                            this.setState({
                                                currentSessionData: {
                                                    ...currentSessionData,
                                                    sessionTotalPackagesDone: newAmount,
                                                },
                                                pause: false,
                                                dontPause: true,
                                                restartingBox: true,
                                            })
                                            // })
                                            // .catch((error) => {
                                            //     console.error('Setup Error ', error)
                                            // })
                                            // })
                                        }}
                                    >
                                        Ricomincia scatola
                                    </Button>
                                    {this.state.pause && (
                                        <Button
                                            color="green"
                                            onClick={() => {
                                                this.setState({ pause: false, dontPause: true })
                                            }}
                                        >
                                            Riprendi
                                        </Button>
                                    )}
                                    {!this.state.pause && (
                                        <Button
                                            color="yellow"
                                            onClick={() => {
                                                this.setState({ pause: true })
                                            }}
                                        >
                                            Pausa
                                        </Button>
                                    )}

                                    <Progress style={{ width: '100%' }} percent={progress} color="green" />
                                </View>
                            </Segment>
                        </View>
                    )}
                    {this.state.loading && <Loader>Caricamento in corso...</Loader>}
                </Dimmer>
                <View row fullw fullh style={{ overflowY: 'scroll' }}>
                    <View column fullw fullh>
                        <Default>
                            <Step.Group key="stepgroup">
                                <Step active={step === 0} completed={step > 0}>
                                    <Icon name="book" />
                                    <Step.Content>
                                        <Step.Title>Confezione</Step.Title>
                                        <Step.Description>Seleziona confezione</Step.Description>
                                    </Step.Content>
                                </Step>

                                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                                    <Icon name="hdd" />
                                    <Step.Content>
                                        <Step.Title>Imbustamento</Step.Title>
                                        <Step.Description>Scansiona i codici</Step.Description>
                                    </Step.Content>
                                </Step>

                                <Step active={step === 2} completed={step > 2} disabled={step < 2}>
                                    <Icon name="check" />
                                    <Step.Content>
                                        <Step.Title>Completato</Step.Title>
                                        <Step.Description>Finalizzazione</Step.Description>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Default>
                        <View column fullw fullh style={{ marginTop: 0 }} key="stepcontent">
                            {
                                {
                                    0: (
                                        <View column fullh style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll' }}>
                                            <Default>
                                                <Form>
                                                    <Form.Group widths="equal">
                                                        <Form.Field
                                                            id="form-input-control-article-code"
                                                            control={Input}
                                                            label="Codice ordine interno"
                                                            name="scannedCode"
                                                            value={scannedCode}
                                                            onChange={(e) => this.handleInput(e)}
                                                            placeholder="15"
                                                            style={{
                                                                fontSize: 64,
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Default>
                                            <Mobile>
                                                <Form>
                                                    <Form.Group widths="equal">
                                                        <Form.Field
                                                            id="form-input-control-article-code"
                                                            control={Input}
                                                            label="Codice articolo"
                                                            name="scannedCode"
                                                            value={scannedCode}
                                                            onChange={(e) => this.handleInput(e)}
                                                            placeholder="ARTI0000"
                                                            style={{
                                                                fontSize: 32,
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Mobile>
                                            {/*
                                        Table containing all information about open jobs/work orders
                                    */}

                                            <div style={{ height: 1000, overflowY: 'scroll' }}>
                                                <WorkOrders
                                                    isEmployee={true}
                                                    showHistory={false}
                                                    noActions={true}
                                                    noWarehouse={true}
                                                    hideQuantity={true}
                                                    hideBarcode={true}
                                                    noLinks={true}
                                                    fromPackaging={true}
                                                    onRowClicked={async (obj) => {
                                                        console.log('onRowClick WorkOrders', obj)
                                                        const scannedCodop = obj.workOrderId
                                                        const workOrder = await this.props.network.getWorkOrder(scannedCodop, true)
                                                        const article = await this.props.network.getStdArticle(
                                                            workOrder.data.articleId,
                                                            undefined,
                                                            true
                                                        )
                                                        const scannedArtiCode = workOrder.data.articleId ? workOrder.data.workCode : ''
                                                        const scannedSeq = 0

                                                        this.setState({
                                                            scannedArtiCode,
                                                            scannedCodop,
                                                            scannedSeq,
                                                            selectedWorkOrder: { ...workOrder.data, ...article },
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <Table>
                                                <Table.Header>
                                                    <Table.HeaderCell>Cliente</Table.HeaderCell>
                                                    <Table.HeaderCell>Commessa</Table.HeaderCell>
                                                    <Table.HeaderCell>Consegna</Table.HeaderCell>
                                                    <Table.HeaderCell>Articolo</Table.HeaderCell>
                                                    <Table.HeaderCell>Kit</Table.HeaderCell>
                                                    {/* <Table.HeaderCell>Qta per kit</Table.HeaderCell> */}
                                                    <Table.HeaderCell>Qta</Table.HeaderCell>
                                                    <Table.HeaderCell>Residuo</Table.HeaderCell>
                                                    <Table.HeaderCell>Pronti</Table.HeaderCell>
                                                </Table.Header>
                                                <Table.Body>
                                                    {artisForCode && artisForCode[scannedArtiCode] && (
                                                        // <View around fullw fullh row wrap style={{ overflowY: 'scroll' }}>
                                                        <>
                                                            {artisForCode[scannedArtiCode].map((arti, index) => {
                                                                let multiplier = 1
                                                                let totalDoneForCurrentArti = 0
                                                                if (sessionDataForCode) {
                                                                    let parentOfAllPackages = null
                                                                    for (let i in arti.packageobj) {
                                                                        if (!arti.packageobj[i].packageParent) {
                                                                            parentOfAllPackages = arti.packageobj[i]
                                                                        } else {
                                                                            //
                                                                        }
                                                                        // Deomment if needed
                                                                        // multiplier *= arti.packageobj[i].pcsPerPackage
                                                                    }
                                                                    const sessionOfAllSessions = arti.jobCode
                                                                        ? sessionDataForCode[this.getSessionKeyFromArti(arti)]
                                                                        : null
                                                                    if (sessionOfAllSessions) {
                                                                        for (const session of sessionOfAllSessions) {
                                                                            if (
                                                                                session.sourcePackage.packageId === parentOfAllPackages.packageId &&
                                                                                session.jobCode === arti.jobCode
                                                                            ) {
                                                                                totalDoneForCurrentArti = session.sessionAvailablePackages
                                                                            }
                                                                        }
                                                                        //
                                                                        totalDoneForCurrentArti *= multiplier
                                                                    }
                                                                }

                                                                let customerToRender = arti.customerName ? arti.customerName : '--'
                                                                if (this.state.customeroverride && !arti.customerName) {
                                                                    customerToRender = this.state.customeroverrideName
                                                                }

                                                                let clientWorkOrderToRender = arti.job ? arti.clientWorkCode : '--'
                                                                if (this.state.articleclientcodeoverride && !arti.clientWorkCode) {
                                                                    clientWorkOrderToRender = this.state.articleclientcodeoverride
                                                                }

                                                                let quantityToShow = this.state.readyquantity
                                                                    ? this.state.readyquantity
                                                                    : totalDoneForCurrentArti

                                                                return (
                                                                    <Table.Row
                                                                        key={`${arti.workCode}_${index}`}
                                                                        onClick={() => {
                                                                            //
                                                                            this.setState({
                                                                                selectedWorkOrder: arti,
                                                                            })
                                                                        }}
                                                                        className={
                                                                            this.state.selectedWorkOrder &&
                                                                            this.state.selectedWorkOrder.jobCode === arti.jobCode &&
                                                                            this.state.selectedWorkOrder.workCode === arti.workCode &&
                                                                            this.state.selectedWorkOrder.deliveryDate === arti.deliveryDate
                                                                                ? 'rowSelected'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        <Table.Cell>{customerToRender}</Table.Cell>
                                                                        <Table.Cell>{arti.jobCode ? arti.jobCode : '--'}</Table.Cell>
                                                                        <Table.Cell>
                                                                            {arti.deliveryDate
                                                                                ? moment(arti.deliveryDate).format('DD/MM/YYYY')
                                                                                : '--'}
                                                                        </Table.Cell>
                                                                        <Table.Cell>{arti.articleCode}</Table.Cell>
                                                                        <Table.Cell>{arti.jobCode ? arti.kit : arti.kit_code}</Table.Cell>
                                                                        {/* <Table.Cell>{arti.kit !== 'SINGOLO' ? arti.kitqta : 1}</Table.Cell> */}
                                                                        <Table.Cell>{arti.productQuantity ? arti.productQuantity : '--'}</Table.Cell>
                                                                        {/* Session Verified Packages of final package for arti */}
                                                                        <Table.Cell>
                                                                            {arti.remainingQuantity ? arti.remainingQuantity : '--'}
                                                                        </Table.Cell>
                                                                        <Table.Cell>{quantityToShow}</Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })}
                                                        </>
                                                    )}

                                                    {/*
                                        ACTIVE SESSIONS CONTAIN THE AMOUNT OF BOXES OF A SPECIFIC TYPE OF A SPECIFIC ARTICLE FOR A SPECIFIC CLIENT ORDER AND WHICH PACKAGE WE SHOULD BE FILLING WITH THEM.
                                        IF ACTIVE SESSIONS FOR THE NEXT PACKAGE ARE AVAILABLE SHOW THE USER TO CHOOSE WHICH ONE WE'RE FILLING
                                    */}
                                                </Table.Body>
                                            </Table>

                                            {sessionDataForCode && sessionDataForCode[key] && <Table></Table>}
                                            <View fullw fullh column>
                                                <Table>
                                                    <Table.Header>
                                                        <Table.HeaderCell>Confezione</Table.HeaderCell>
                                                        <Table.HeaderCell>Pz per conf.</Table.HeaderCell>
                                                        <Table.HeaderCell>Articoli</Table.HeaderCell>
                                                        {/* <Table.HeaderCell>Verificati</Table.HeaderCell> */}
                                                        {/* <Table.HeaderCell>Disponibili da confezionare</Table.HeaderCell> */}
                                                        <Table.HeaderCell>Stampe</Table.HeaderCell>
                                                        <Table.HeaderCell>Da imballare</Table.HeaderCell>
                                                        <Table.HeaderCell>Azione</Table.HeaderCell>
                                                    </Table.Header>
                                                    <Table.Body>{buttons}</Table.Body>
                                                </Table>
                                            </View>
                                        </View>
                                    ),
                                    1: currentSessionData && (
                                        <View column fullh style={{ height: '95%' }}>
                                            <Form error={this.state.wrongCode} errorMessage={this.state.errorMessage}>
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-article-verifiedcode"
                                                        control={Input}
                                                        label="Codice articolo"
                                                        name="verifiedCode"
                                                        value={verifiedCode}
                                                        onChange={(e) => this.handleInput(e)}
                                                        placeholder="ARTI0000"
                                                        style={{
                                                            fontSize: 64,
                                                        }}
                                                        // disabled={currentSessionData.sessionVerifiedPackages >= currentSessionData.sessionAvailablePackages}
                                                    />
                                                </Form.Group>
                                            </Form>

                                            {this.state.wrongCode && (
                                                <View fullw around>
                                                    <Segment style={{ width: '100%' }}>
                                                        <Header style={{ color: 'red', textAlign: 'center', fontSize: 24 }}>
                                                            {this.state.errorMessage}
                                                        </Header>
                                                    </Segment>
                                                </View>
                                            )}

                                            {currentSessionData.sessionVerifiedPackages >= currentSessionData.sessionAvailablePackages && (
                                                <View fullw around>
                                                    <Segment style={{ width: '100%' }}>
                                                        <Header style={{ color: 'red', textAlign: 'center', fontSize: 48 }}>
                                                            Attenzione, verificando codici non precedentemente stampati
                                                        </Header>
                                                    </Segment>
                                                </View>
                                            )}

                                            <View fullw around style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                                                <Table style={{ width: '100%' }}>
                                                    <Table.Header>
                                                        <Table.HeaderCell>Ordine cliente</Table.HeaderCell>
                                                        <Table.HeaderCell>Articolo</Table.HeaderCell>
                                                        <Table.HeaderCell>Kit</Table.HeaderCell>
                                                        <Table.HeaderCell>Contenitore</Table.HeaderCell>
                                                        <Table.HeaderCell>Destinazione</Table.HeaderCell>
                                                        {/* <Table.HeaderCell>Disponibili</Table.HeaderCell> */}
                                                        <Table.HeaderCell>Verificati</Table.HeaderCell>
                                                        <Table.HeaderCell>Da confezionare</Table.HeaderCell>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {(currentSessionData.sessionKit === 'SINGOLO' || !currentSessionData.kitArtis) && (
                                                            <Table.Row>
                                                                <Table.Cell>{currentSessionData.sessionClientOrder}</Table.Cell>
                                                                <Table.Cell>{scannedArtiCode}</Table.Cell>
                                                                <Table.Cell>{currentSessionData.sessionKit}</Table.Cell>
                                                                <Table.Cell>{currentSessionData.sourcePackage.packageName}</Table.Cell>
                                                                <Table.Cell>
                                                                    {currentSessionData.targetPackage
                                                                        ? currentSessionData.targetPackage.packageName
                                                                        : 'PZ'}
                                                                </Table.Cell>
                                                                {/* <Table.Cell>
                                                                    {currentSessionData.sessionAvailablePackages /
                                                                        currentSessionData.sourcePackage.pcsPerPackage}
                                                                </Table.Cell> */}
                                                                <Table.Cell>{currentSessionData.sessionVerifiedPackages}</Table.Cell>
                                                                {currentSessionData.targetPackage && (
                                                                    <Table.Cell>
                                                                        {currentSessionData.targetPackage.pcsPerPackage
                                                                            ? currentSessionData.targetPackage.pcsPerPackage /
                                                                              currentSessionData.sourcePackage.pcsPerPackage
                                                                            : currentSessionData.targetPackage.packageSize /
                                                                              currentSessionData.sourcePackage.packageSize}
                                                                    </Table.Cell>
                                                                )}
                                                            </Table.Row>
                                                        )}

                                                        {currentSessionData.sessionKit !== 'SINGOLO' && !!currentSessionData.kitArtis && (
                                                            <>
                                                                {currentSessionData.kitArtis.map((value, index) => {
                                                                    return (
                                                                        <Table.Row key={`${value.workCode}_${index}`}>
                                                                            <Table.Cell>{currentSessionData.sessionClientOrder}</Table.Cell>
                                                                            <Table.Cell>{value.workCode}</Table.Cell>
                                                                            <Table.Cell>{currentSessionData.sessionKit}</Table.Cell>
                                                                            <Table.Cell>{currentSessionData.sourcePackage.packageName}</Table.Cell>
                                                                            <Table.Cell>
                                                                                {currentSessionData.targetPackage
                                                                                    ? currentSessionData.targetPackage.packageName
                                                                                    : ''}
                                                                            </Table.Cell>
                                                                            {/* <Table.Cell>{value.session.sessionAvailablePackages}</Table.Cell> */}
                                                                            <Table.Cell>{value.session.sessionVerifiedPackages}</Table.Cell>
                                                                            <Table.Cell>
                                                                                {value.session.sourcePackage
                                                                                    ? value.session.sessionTargetPackageCount /
                                                                                      value.session.sourcePackage.pcsPerPackage
                                                                                    : '--'}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </View>
                                        </View>
                                    ),
                                    2: currentSessionData && (
                                        <View column fullh style={{ height: '95%' }}>
                                            {/* {!this.state.hasQueryString && ( */}
                                            <Labeling
                                                isPaused={this.isPaused}
                                                abort={this.state.abort}
                                                numberOfPackagesToMake={this.state.numberOfPackagesToMake}
                                                toFill={toFill ? toFill : 1}
                                                articleId={scannedArtiCode}
                                                packageId={
                                                    currentSessionData.targetPackage
                                                        ? currentSessionData.targetPackage.packageId
                                                        : currentSessionData.sourcePackage
                                                }
                                                customerId={
                                                    currentSessionData.customerId ? currentSessionData.customerId : this.state.customeroverride
                                                }
                                                customerName={this.state.customeroverride ? this.state.customeroverrideName : null}
                                                jobCode={currentSessionData.sessionClientOrder}
                                                arti={{ ...selectedWorkOrder, op: this.state.scannedCodop, lotto: this.state.scannedCodop }}
                                                currentSession={currentSessionData}
                                                onStartPrinting={() => {
                                                    this.setState({ printing: true })
                                                }}
                                                onPrintLabel={(remaining, current, startingPackagesDone, currentPackagerCounter, forceAbort) => {
                                                    console.log('onPrintLabel', forceAbort)
                                                    if (forceAbort || this.state.abort) {
                                                        this.setState({ printing: false, abort: false, pause: false })
                                                        this.prev()
                                                    } else if (current > 0) {
                                                        if (current !== currentSessionData.sessionTotalPackagesDone) {
                                                            const sessionobj = {
                                                                ...currentSessionData,
                                                                addToSourceCounter: true,
                                                                printed: true,
                                                            }

                                                            if (this.props.printer === 'zebraimball' || this.props.printer === 'zebraimball2') {
                                                                sessionobj.sessionTotalPackagesDone = current - 1
                                                            }

                                                            return network.addSessionData(sessionobj).then((result) => {
                                                                this.setState({
                                                                    currentSessionData: {
                                                                        ...currentSessionData,
                                                                        sessionTotalPackagesDone: current,
                                                                    },
                                                                    dontPause: false,
                                                                })

                                                                if (
                                                                    remaining === 0 &&
                                                                    (this.props.printer !== 'zebraimball' ||
                                                                        this.props.printer === 'zebraimball2' ||
                                                                        this.state.abort ||
                                                                        !this.state.numberOfPackagesToMake)
                                                                ) {
                                                                    this.setState({ printing: false, abort: true, pause: false })
                                                                    this.prev()
                                                                }

                                                                if (this.state.restartingBox) {
                                                                    // this.resetCounter().then(() => {
                                                                    const newAmount =
                                                                        currentSessionData.sessionTotalPackagesDone -
                                                                        (currentSessionData.sessionTotalPackagesDone % toFill)

                                                                    axios
                                                                        .post(`${this.props.network.WEB_REQUEST_URL}set_session_data`, {
                                                                            sessionobj: {
                                                                                ...currentSessionData,
                                                                                sessionTotalPackagesDone: newAmount,
                                                                            },
                                                                        })
                                                                        .then((result) => {
                                                                            this.setState({
                                                                                currentSessionData: {
                                                                                    ...currentSessionData,
                                                                                    sessionTotalPackagesDone: newAmount,
                                                                                },
                                                                                restartingBox: false,
                                                                                dontPause: true,
                                                                                pause: false,
                                                                            })
                                                                        })
                                                                        .catch((error) => {
                                                                            console.error('Setup Error ', error)
                                                                        })
                                                                    // })
                                                                    return newAmount
                                                                }
                                                                return currentSessionData.sessionTotalPackagesDone
                                                            })
                                                        }
                                                    }
                                                }}
                                                isEmployee={true}
                                            />
                                        </View>
                                    ),
                                }[step]
                            }
                            <View fullw style={{ position: 'absolute', bottom: 16, left: 126, zIndex: 10, justifyContent: 'flex-start' }}>
                                {step > 0 && (
                                    <Button disabled={this.state.printing} onClick={() => this.prev()}>
                                        Indietro
                                    </Button>
                                )}
                                {step > 0 && step < 2 && (
                                    <>
                                        <Button
                                            color={'yellow'}
                                            disabled={this.state.printing}
                                            onClick={() => {
                                                this.setState({ verifiedCode: '', step: 2, wrongCode: false }, () => {
                                                    network.getSessionData()
                                                })
                                            }}
                                        >
                                            Passa direttamente alla stampa
                                        </Button>
                                    </>
                                )}
                            </View>
                            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                                {step > 0 && step < 1 && (
                                    <Button
                                        color="black"
                                        onClick={() => this.next()}
                                        disabled={(step === 0 && !startScanning) || this.state.printing}
                                    >
                                        Avanti
                                    </Button>
                                )}
                            </View>
                        </View>
                    </View>
                    <View column fullw fullh style={{ margin: 16, height: '100%', overflowY: 'scroll' }}>
                        <View fullw style={{ height: '93%' }}>
                            {/* {!!this.state.assieme && (
                                <Document
                                    // file="https://hercules.develop.stampind.syncode.it/files/dhl_express_large_palletised_packing_guide_it.pdf"
                                    file={`http://192.168.15.247:4242/files/pdfs/${this.state.assieme.codice}.pdf`}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    style={{ height: '90%' }}
                                    loading={
                                        <View fullw>
                                            <Segment placeholder>
                                                <Header icon>
                                                    <Icon name="hourglass half" />
                                                    Caricando istruzioni di imballaggio...
                                                </Header>
                                            </Segment>
                                        </View>
                                    }
                                    error={
                                        <View fullw>
                                            <Segment placeholder>
                                                <Header icon>
                                                    <Icon name="pdf file outline" />
                                                    Istruzioni di imballaggio non trovate
                                                </Header>
                                            </Segment>
                                        </View>
                                    }
                                >
                                    <View fullw fullh row>
                                        <Page pageNumber={pageNumber} scale="1" style={{ height: '90%' }} className="smallPdf" />
                                    </View>
                                </Document>
                            )} */}
                        </View>
                        <View fullw row between>
                            <Button
                                disabled={this.state.printing}
                                onClick={() => {
                                    if (pageNumber >= 1) {
                                        this.setState({ pageNumber: pageNumber - 1 })
                                    }
                                }}
                            >
                                Precedente
                            </Button>
                            <Button
                                disabled={this.state.printing}
                                onClick={() => {
                                    if (pageNumber < numPages) {
                                        this.setState({ pageNumber: pageNumber + 1 })
                                    }
                                }}
                            >
                                Successiva
                            </Button>
                        </View>
                    </View>
                </View>
            </Dimmer.Dimmable>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getStdArticleList,
        getPackageList: {
            fetchingPackages,
            data: { data: packages },
        },
        getSessionData: {
            fetching,
            data: { data: session },
        },
        loginEmployee: {
            data: { user: worker },
        },
        getSyncJobs,
        printer,
    } = state

    let artiList = getStdArticleList

    return {
        artis: artiList && artiList.data ? artiList.data.data : [],
        fetchingArtis: artiList ? artiList.fetching : false,
        fetchingPackages,
        packages,
        fetching,
        session,
        worker,
        getSyncJobs,
        printer,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(connectNetwork(Packaging)))
